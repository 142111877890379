const body = document.querySelector('body')

const fixedPoint = 10
let ticking = false

const scrollFunction = () => {
    if (!ticking) {
        window.requestAnimationFrame(() => {
            if (window.pageYOffset >= fixedPoint) {
                body.classList.add('scrolled')
            } else {
                body.classList.remove('scrolled')
            }
            ticking = false
        })

        ticking = true
    }
}

scrollFunction()
window.addEventListener('scroll', scrollFunction)
