class IntersectIO {
   constructor(el, options) {
      this.selector = el

      // Callbacks.
      this.onChange = function() {}
      this.onEnter = function() {}
      this.onLeave = function() {}

      // IntersectionObserver.
      this.once = false
      this.threshold = 0.5
      this.rootMargin = '0px 0px 0px 0px'
      this.root = null
      this.hasEntered = false

      // Override defaults.
      Object.assign( this, options )

      this.init()
   }

   init() {
      this.initObserver()
   }

   destroy() {
      if ( this.observer ) {
         this.observer.disconnect()
      }
   }

   initObserver() {
      const observerOptions = {
         threshold: this.threshold,
         root: this.root,
         rootMargin: this.rootMargin,
      }

      const isIntersecting = () => {

         this.onEnter()

         // Remove observer.
         if ( this.once ) {
            this.observer.unobserve(entries[0].target)
         }
      }

      const isNotIntersecting = () => {
         this.onLeave()
      }

      this.observer = new IntersectionObserver((entries) => {

         if (!entries[0].isIntersecting) {
            isNotIntersecting()
         } else {
            isIntersecting()
         }
         this.onChange()

      }, observerOptions )

      this.observer.observe(this.selector)
   }
}

export default IntersectIO