import Vue from 'vue'
import Vuex from 'vuex'
import { polyfill } from 'es6-promise'
polyfill()

Vue.use(Vuex)

const store = new Vuex.Store({

    state: {
        caseStudySlides: window.caseStudySlides || [],
        currentIndex: 0,
        translateValue: null
    },

    getters: {
        currentSlide (state) {
            return state.caseStudySlides[state.currentIndex]
        }
    },

    actions: {
        updateIndex ({ commit }, index) {
            commit('setCurrentIndex', index)
        },

        decreaseCurrentIndex ({ commit, state }) {
            commit('setCurrentIndex', state.currentIndex - 1)
        },

        increaseCurrentIndex ({ commit, state }) {
            commit('setCurrentIndex', state.currentIndex + 1)
        },

        updateCurrentTouchTranslate ({ commit }, translateValue) {
            commit('setTranslateValue', translateValue)
        }
    },

    mutations: {
        setCurrentIndex (state, index) {
            state.currentIndex = index
        },

        setTranslateValue (state, value) {
            state.translateValue = value
        }
    }

})

export default store