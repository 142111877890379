// Detect Touchscreen Devices
const motionQuery = matchMedia("(pointer: coarse)")
const updateLocalStorage = ( value ) => {
   localStorage.setItem( 'touchDevice', value )
}
const touchDeviceCheck = () => {
	if ( motionQuery.matches ) {
		updateLocalStorage( true )
	} else {
		updateLocalStorage( false )
	}
}

const isIE11 = window.navigator.userAgent.indexOf("Trident/7.0") > -1
if ( ! isIE11 ) {
   touchDeviceCheck()
   motionQuery.addEventListener('change', touchDeviceCheck)
}
