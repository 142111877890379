import IntersectIO from "./intersectIO"

const toggleIndustries = ( isEntering, tile, index ) => {
   if ( localStorage.getItem('touchDevice') === 'true' ) {
      setTimeout(() => {
         isEntering
         ? tile.classList.add('-noHover')
         : tile.classList.remove('-noHover')
      }, 100 * ( index + 1 ) )
   }
}

const isIE11 = window.navigator.userAgent.indexOf("Trident/7.0") > -1

if ( ! isIE11 ) {
   document.querySelectorAll('.aspenIndustries')
   .forEach( ( el ) => {
      const tiles = el.querySelectorAll('.aspenIndustries__card')
      tiles.forEach( ( tile, index) => {
         new IntersectIO( tile, {
            threshold: 1,
            rootMargin: '0% 0% -5%',
            onEnter: function() {
               toggleIndustries( true, tile, index )
            },
         })
      })
   })
}
