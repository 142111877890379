<template>
    <div class="caseStudySlider__logoSlider">
        <Swiper
            ref="mainSlider"
            :options="swiperOptions"
            @slide-change="updateCurrentIndex"
            @slide-change-transition-end="updateCurrentTranslate"
        >
            <SwiperSlide
                v-for="caseStudy in caseStudySlides"
                :key="caseStudy.title"
            >
                <div class="caseStudySlider__logoWrapper">
                    <img :src="caseStudy.logo" alt="" class="caseStudySlider__logo">
                </div>
            </SwiperSlide>
        </Swiper>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { mapState } from 'vuex'

export default {

    data () {
        return {
            swiperOptions: {
                direction: 'horizontal'
            },
            currentTranslate: 0
        }
    },

    methods: {
        updateCurrentIndex (swiper) {
            const { realIndex } = swiper

            this.$store.dispatch('updateIndex', realIndex)
        },

        updateCurrentTranslate (swiper) {
            this.currentTranslate = swiper.getTranslate()
        }
    },

    watch: {
        currentIndex (val) {
            const { $swiper } = this.$refs.mainSlider


            requestAnimationFrame(() => {
                $swiper.slideTo(val)
            })
        },

        translateValue (value) {
            const { $swiper } = this.$refs.mainSlider
            const newTranslate = this.currentTranslate - value
            if (newTranslate <= 0) {
                $swiper.setTranslate(newTranslate)
            }
        }
    },

    components: {
        Swiper,
        SwiperSlide
    },

    computed: {
        ...mapState(['caseStudySlides', 'currentIndex', 'translateValue'])
    }

}
</script>