<template>
    <div class="caseStudySlider__mainSliderWrapper">
        <swiper 
            :class="'caseStudySlider__mainSliderTray'"
            ref="mainSlider"
            :options="swiperOptions"
            @slide-change="updateCurrentIndex"
            @touch-start="setInitialXValue"
            @touch-move="updateLogoSliderTranslate"
        >
            <swiper-slide 
                v-for="(caseStudy, index) in slicedCaseStudies"
                :key="caseStudy.title"
            >   
                <div 
                    class="caseStudySlider__mainSliderColumn"
                    @click="goToIndex(index)"
                >
                    <div class="caseStudySlider__logoWrapper">
                        <img :src="caseStudy.logo" alt="" class="caseStudySlider__logo">
                    </div>
                    <div class="caseStudySlider__columnImageWrapper">
                        <img :src="caseStudy.image" alt="" class="caseStudySlider__columnImage">
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>   
                <div>
                    <!-- Hacky Slider Dummy -->
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { mapState } from 'vuex'

export default {

    data () {
        return {
            swiperOptions: {
                direction: 'horizontal'
            },
            initialXValue: null
        }
    },

    components: {
        Swiper,
        SwiperSlide
    },

    methods: {
        updateCurrentIndex (swiper) {
            const { realIndex } = swiper

            this.$store.dispatch('updateIndex', realIndex)
        },

        setInitialXValue (swiper) {
            this.initialXValue = swiper.getTranslate()
        },

        updateLogoSliderTranslate (swiper) {
            const changeValue = this.initialXValue - swiper.getTranslate()

            this.$store.dispatch('updateCurrentTouchTranslate', changeValue)
        },

        goToIndex(index) {
            this.$store.dispatch('updateIndex', index + 1)
        }
    },

    watch: {
        currentIndex (val) {
            const { $swiper } = this.$refs.mainSlider

            requestAnimationFrame(() => {
                $swiper.slideTo(val)
            })
        }
    },

    computed: {
        ...mapState(['caseStudySlides', 'currentIndex']),

        slicedCaseStudies () {
            return this.caseStudySlides.slice(1, this.caseStudySlides.length)
        }
    }

}
</script>