<template>
    <nav class="caseStudySlider__nav">
        <button
            class="caseStudySlider__navLeft"
            :class="{ '-disable': isAtFirstSlide }"
            @click="previousSlide"
        >
            <img src="media/icons/previous.png" alt="" class="caseStudySlider__navLeftIcon">
        </button>
        <p class="caseStudySlider__navText">
            <span class="caseStudySlider__valueIndex">{{ currentSlideNumber }}</span>
            of
            <span class="caseStudySlider__valueTotal">{{ slideCount }}</span>
        </p>
        <button
            class="caseStudySlider__navRight"
            :class="{ '-disable': isAtLastSlide }"
            @click="nextSlide"
        >
            <img src="media/icons/next.png" alt="" class="caseStudySlider__navLeftIcon">
        </button>
    </nav>
</template>

<script>
import { mapState } from 'vuex'

export default {

    computed: {
        ...mapState(['caseStudySlides', 'currentIndex']),

        slideCount () {
            return this.caseStudySlides.length
        },

        currentSlideNumber () {
            return this.currentIndex + 1
        },

        isAtFirstSlide () {
            return this.currentIndex === 0
        },

        isAtLastSlide () {
            return this.currentIndex >= this.caseStudySlides.length - 1
        }
    },

    methods: {
        previousSlide () {
            this.$store.dispatch('decreaseCurrentIndex')
        },

        nextSlide () {
            this.$store.dispatch('increaseCurrentIndex')
        }
    }

}
</script>