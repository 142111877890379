const videoLocation  = document.getElementById("video-hero");

if ( videoLocation ) {
   window.onload = removeVideoOnMobile();
   function removeVideoOnMobile() {
      if (window.innerWidth < 992){
         videoLocation.removeAttribute("autoplay");
         videoLocation.setAttribute('preload', 'none');
      };
   }
}