const mobileTrigger = document.querySelector('.js-mobileTrigger')
const mobileMenu = document.querySelector('.js-mobileMenu')
const search = document.querySelector('.js-search')
const subBack = document.querySelector('.js-subBack')

const dropdownMenus = [...document.querySelectorAll('.js-dropdown')]
const dropdownTriggers = [...document.querySelectorAll('.js-dropdownTrigger')]

mobileTrigger.addEventListener('click', event => {
    mobileTrigger.classList.toggle('is-active')
    mobileMenu.classList.toggle('-opened')
    search.classList.toggle('-hide')

    document.documentElement.classList.toggle('-lock')
    document.body.classList.toggle('-lock')

    dropdownMenus.forEach(dropdownMenu => dropdownMenu.classList.remove('-opened'))
    subBack.classList.remove('-active')
})

dropdownTriggers.forEach(dropdownTrigger => {
    dropdownTrigger.addEventListener('click', event => {
        const { dropdown } = dropdownTrigger.dataset
        const associatedDropdown = document.querySelector(`.js-dropdown[data-dropdown="${dropdown}"]`)

        associatedDropdown.classList.add('-opened')
        subBack.classList.add('-active');
    })
})

subBack.addEventListener('click', event => {
    dropdownMenus.forEach(dropdownMenu => dropdownMenu.classList.remove('-opened'))
    subBack.classList.remove('-active')
})
