const dropdowns = [...document.querySelectorAll('.js-dropdownParent')]

dropdowns.forEach(function applyKeyboardOperation (dropdown) {
    const trigger = dropdown.querySelector('.js-navLink')
    const container = dropdown.querySelector('.js-dropdown')
    const focusables = [...container.querySelectorAll('[tabindex]:not(.js-dropdownGroupLink')]
    const tabFocusables = [...container.querySelectorAll('.js-dropdownGroupLink')]

    container.addEventListener('mouseover', () => {
        dropdowns.forEach(dropdownItem => dropdownItem.classList.remove('-opened'))
    })

    const icon = trigger.querySelector('.js-buttonIcon')

    container.addEventListener('focusout', e => {
        if (!container.contains(e.relatedTarget)) {
            focusables.forEach(focusable => {
                focusable.tabIndex = '-1'
            })
            container.classList.remove('-opened')

            trigger.dataset.open = 0
        }
    })

    trigger.addEventListener('keydown', e => {
        if (
            (e.key === 'Enter' || e.key === ' ') &&
            !(trigger.dataset.open * 1) &&
            window.innerWidth > 1000
        ) {
            e.preventDefault()

            focusables.forEach(focusable => {
                focusable.tabIndex = '0'
            })
            container.classList.add('-opened')

            trigger.dataset.open = 1
        }
    })

    container.addEventListener('focusout', e => {
        if (!container.contains(e.relatedTarget)) {
            focusables.forEach(focusable => {
                focusable.tabIndex = '-1'
            })
            if (tabFocusables.length) {
                tabFocusables.forEach(tabFocusable => tabFocusable.tabIndex = '-1')
            }
            container.classList.remove('-opened')
            trigger.dataset.open = 0
        }
    })

    dropdown.addEventListener('keydown', e => {
        if (e.key === 'Escape') {
            focusables.forEach(focusable => {
                focusable.tabIndex = '-1'
            })
            if (tabFocusables.length) {
                tabFocusables.forEach(tabFocusable => tabFocusable.tabIndex = '-1')
            }
            container.classList.remove('-opened')
            trigger.dataset.open = 0

            trigger.focus()
        }
    })
})
