<template>
    <div class="caseStudySlider__currentSlideWrapper">
        <transition-group name="fade">
            <div 
                class="caseStudySlider__backgroundSlide"
                v-for="caseStudy in reducedSlides"
                :key="caseStudy.title"
                :style="`background-image:url('${caseStudy.image}')`"
            >
            </div>
        </transition-group>
        <div class="caseStudySlider__currentSlideTray">
            <SliderNav />
            <LogoSlider />
            <CurrentSlideText />
        </div>
    </div>
</template>

<script>
import SliderNav from './SliderNav'
import LogoSlider from './LogoSlider'
import CurrentSlideText from './CurrentSlideText'
import { mapGetters, mapState } from 'vuex'

export default {

    components: {
        SliderNav,
        LogoSlider,
        CurrentSlideText
    },

    computed: {
        ...mapGetters(['currentSlide']),
        ...mapState(['caseStudySlides', 'currentIndex']),

        reducedSlides () {
            return this.caseStudySlides.slice(0, this.currentIndex + 1)
        }
    }

}
</script>