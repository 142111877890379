import 'core-js/es/number'
import objectFitImages from 'object-fit-images'
import objectFitVideos from 'object-fit-videos'
import './modules/keyboard-focus'
import './modules/body-scroll'
import './modules/mobile-menu'
import './modules/nav-keyboard'
import './modules/language-select'
import './modules/case-study-slider'
import './modules/group-dropdown'
import './modules/scroll-triggers'
import './modules/touch-detect'
import './modules/video-hero'

objectFitImages()
objectFitVideos()

// add fallback class if ie11
if (window.navigator.userAgent.indexOf("Trident/7.0") > -1) {
	document.documentElement.className += " -ie11Fallbacks";
}