const languageDropdownTrigger = document.querySelector('.js-languageTrigger')
const languageDropdown = document.querySelector('.js-dropdownLanguage')
const languageClose = document.querySelector('.js-languageClose')
const focusables = [...languageDropdown.querySelectorAll('[tabindex]')]
const firstFocusable = focusables[1]

languageDropdownTrigger.addEventListener('click', event => {
    languageDropdown.classList.toggle('-opened')

    if (languageDropdownTrigger.dataset.open * 1) {
        focusables.forEach(focusable => {
            focusable.tabIndex = '-1'
        })
        document.documentElement.classList.remove('-lock')
        document.body.classList.remove('-lock')
        
        languageDropdownTrigger.dataset.open = '0'
    } else {
        focusables.forEach(focusable => {
            focusable.tabIndex = '0'
        })


        if (window.innerWidth < 1000) {
            document.documentElement.classList.add('-lock')
            document.body.classList.add('-lock')
        }

        languageDropdownTrigger.dataset.open = '1'
    }
})

languageDropdownTrigger.addEventListener('keydown', e => {
    if (
        (e.key === 'Enter' || e.key === ' ') &&
        !(languageDropdownTrigger.dataset.open * 1) &&
        window.innerWidth > 1000
    ) {
        e.preventDefault()

        focusables.forEach(focusable => {
            focusable.tabIndex = '0'
        })

        languageDropdown.classList.add('-opened')
        firstFocusable.focus()
        languageDropdownTrigger.dataset.open = 1
    }
})

languageDropdown.addEventListener('focusout', e => {
    if (!languageDropdown.contains(e.relatedTarget)) {
        focusables.forEach(focusable => {
            focusable.tabIndex = '-1'
        })
        languageDropdown.classList.remove('-opened')
        languageDropdownTrigger.dataset.open = 0
    }
})

languageDropdown.addEventListener('keydown', e => {
    if (e.key === 'Escape') {
        focusables.forEach(focusable => {
            focusable.tabIndex = '-1'
        })
        languageDropdown.classList.remove('-opened')
        languageDropdownTrigger.dataset.open = 0

        languageDropdownTrigger.focus()
    }
})

languageClose.addEventListener('click', event => {
    languageDropdown.classList.remove('-opened')

    focusables.forEach(focusable => {
        focusable.tabIndex = '-1'
    })

    document.documentElement.classList.remove('-lock')
    document.body.classList.remove('-lock')

    languageDropdownTrigger.dataset.open = 0
})