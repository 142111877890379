let usingMouse
const { body } = document

const preFocus = function (event) {
    usingMouse = (event.type === 'mousedown')
}

const addFocus = function (event) {
    if (usingMouse) { event.target.classList.add('focus--mouse') }
}

const removeFocus = function (event) {
    event.target.classList.remove('focus--mouse')
}

const bindEvents = function () {
    body.addEventListener('keydown', preFocus)
    body.addEventListener('mousedown', preFocus)
    body.addEventListener('focusin', addFocus)
    body.addEventListener('focusout', removeFocus)
}

bindEvents()
